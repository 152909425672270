import { Alert, Button, Checkbox, Input, Modal, Select, Space, Table, Tag, Tooltip, Typography } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { AiOutlineDelete, AiOutlineLogout } from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";
import HeadingContainer, { flagStyle } from "../../components/Headings/HeadingContainer";
import { catcher } from "../../firebase/catcher";
import { logout } from "../../firebase/other";
import { deleteUserById } from "../../firebase/users/delete";
import { accessConfig, getAllUsers, getUserAccess } from "../../firebase/users/get";
import { createUser, updateUser } from "../../firebase/users/set";
import { getId } from "../../helpers/id";
import { getTimeBasedGreeting } from "../../helpers/time";
import _ from "lodash";
import "./style.css";
import formatTime from "../../helpers/datetime";
// import ExcelDownloader from "../Excel/generateExcel";
import { EditOutlined } from "@ant-design/icons";
import { auth } from "../../firebase/config";
import DownloadExcel from "../Excel/downloadExcel";
import isAdmin, { isAmbassador, isFellow, isRemoteAmbassador } from "../../firebase/users/config";
import useSize from "../../components/use/useSize";
import { flattenJson } from "../../helpers/obj";
import Summary from "../Summary/HomePageSummary";
import MainContext from "../../contexts/mainContext";
import { CiSettings } from "react-icons/ci";
import { IoSettings, IoSettingsSharp, IoTimeOutline } from "react-icons/io5";
import { PiClockDuotone, PiToolboxDuotone, PiToolboxThin } from "react-icons/pi";

import { ConfigContext } from "../../contexts/configContext";
import { CgToolbarBottom } from "react-icons/cg";
import { useAtom } from "jotai";
import { allUsersAtom, usersAtom } from "../../atoms/users";

import UserSummaryDrawer from "./Summary";
import { applicationConfigs } from "./home.config";
import { BiRewindCircle } from "react-icons/bi";
import FormComponent from "../forms/FormComponent/FormComponent";
import { wicTexasConfig } from "../forms/wic_texas/config";
import SNAPPdf from "../Users/SNAP/PDF/SNAPPDF";
import { OmitProps } from "antd/es/transfer/ListBody";
import NotesComponent from "./comp/Notes";
import ApplicationStatus from "./ApplicationStatus";

const { Option } = Select;

export const generateTags = (userx) => {
  const tags = [];
  const user = flattenJson(userx);

  Object.entries(applicationConfigs).forEach(([key, value]) => {
    // console.log(value)
    if (user[value.uploaded_key] === true) {
      tags.push(
        <Tag color="default" key={value.title}>
          {value.title}
        </Tag>
      );
    } else if (typeof user[value.uploaded_key] === "string") {
      tags.push(
        <Tag color="processing" key={value.title}>
          {value.title}
        </Tag>
      );
    }
  });

  return <div>{tags}</div>;
};

export default function Home() {
  const [users, setUsers] = useAtom(usersAtom);
  const [allUsers,setAllUsers]=useAtom(allUsersAtom)
  const [filteredUsers, setFilteredUsers] = useState(undefined);
  const { isExtraSmall, isSmall } = useSize();
  const isMobile = isSmall || isExtraSmall;
  const [updating, setUpdating] = useState(false);
  const navigate = useNavigate();
  const { loc } = useContext(MainContext);
  const centers = useContext(ConfigContext)[0];
  const [centerName, setCenterName] = useState();
  const [selectValue, setSelectValue] = useState("id");
  const [modals, setModals] = useState({ selectCenterModal: false });



  useEffect(() => {
    console.log("centers: ",centers)
    const savedValue = localStorage.getItem("ambassadorName");
    setCenterName(savedValue ? JSON.parse(savedValue) : null);
    console.log(centers?.names);
  }, [centers]);

  useEffect(() => {
    if (centers) {
      const savedValue = localStorage.getItem("ambassadorName");
      const centerName = savedValue ? JSON.parse(savedValue) : null;
      if (centerName === "-" || !centerName) {
        setModals({ ...modals, selectCenterModal: true });
      }
    }
  }, [centers]);

  const handleUpdateUser = async (id, updatedObj, dontShowLoading = false) => {
    console.log(id, updatedObj);
    catcher(
      async () => {
        await updateUser(id, updatedObj, loc);

        //update local user
        if (dontShowLoading === false)
          setUsers(
            users.map((user) => {
              if (flattenJson(user).id === id) return { ...user, ...updatedObj };
              else return user;
            })
          );
      },
      { setLoading: dontShowLoading ? () => {} : setUpdating }
    );
  };

  useEffect(() => {
    catcher(
      async () => {
        const all_users = await getAllUsers(loc);
        setAllUsers(all_users)
        setUsers(all_users?.filter((user) => user.name || user.first_name));
      },
      { loading_msg: users ? "Refreshing users..." : "Loading users...", success_msg: "" }
    );
    setSelectValue("id");
  }, []);
  const handleNewUser = () => {
    catcher(async () => {
      const id = await createUser({
        loc,
        createdOn: getId("date"),
        created_by_email: auth.currentUser.email,
      });
      navigate("/user?id=" + id);
    });
  };
  const handleRowClick = (record, rowIndex) => {
    return {
      onClick: (event) => {
        console.log("Row clicked!", record, rowIndex);
        navigate("/user?id=" + record?.id);
      },
    };
  };
  const handleSetAmbassadorField = (value) => {
    localStorage.setItem("ambassadorName", JSON.stringify(value));
    console.log("Ambassador Name: " + value);
  };

  const handleDelete = async (userToBeDeleted) => {
    if (!isAdmin()) return alert("Option has been disabled for non-admins.");
    if (window.confirm("Do you want to delete " + userToBeDeleted.name)) {
      await catcher(async () => {
        await deleteUserById(userToBeDeleted.id);
        setUsers(
          users.filter((userObj) => {
            return flattenJson(userObj).id !== flattenJson(userToBeDeleted).id;
          })
        );
      });
    }
  };

  const getRowClassName = (record) => {
    switch (record.application_type) {
      case "Medicaid":
        return "row-medicaid";
      case "Medicare":
        return "row-medicare";
      case "SNAP":
        return "row-snap";
      default:
        return "";
    }
  };

  useEffect(() => {
    setFilteredUsers(users);
  }, [users]);

  const filterUsers = (val) => {
    setSelectValue(val);
    const resultArray = val.split("|");
    let check;
    if (resultArray.length < 2) {
      val = val;
    } else {
      val = resultArray[0];
      check = resultArray[1];
    }
    const filtered = users.filter((user) => {
      const value = _.get(user, val);
      if (resultArray.length < 2) {
        return value === true || Boolean(value);
      } else {
        return value === check;
      }
    });
    setFilteredUsers(filtered);
    // console.log(filtered)
  };

  const titleData = (
    <Space style={{ paddingLeft: "20px", justifyContent: "space-between", width: "100%" }}>
      {/* <Space style={{ display: "none", justifyContent: "flex-end", paddingRight: "20px" }}>
        <h3>Filter</h3>
        <Select
          title="Filter"
          defaultValue="id"
          size="middle"
          style={{ width: "190px" }}
          value={selectValue}
          onChange={filterUsers}
          options={[
            {
              value: "id",
              label: "All",
            },
            {
              value: "snap_full_uploaded.value",
              label: "SNAP Uploaded",
            },
            {
              value: "liheap_uploaded.value",
              label: "LIHEAP Uploaded",
            },
            {
              value: "lifeline_uploaded.value",
              label: "LIFELINE Uploaded",
            },
            {
              value: "snap_full_faxed.value",
              label: "SNAP Fax Sent",
            },
            {
              value: "application_type.value|SNAP",
              label: "SNAP applications",
            },
            {
              value: "application_type.value|Medicaid",
              label: "Medicaid applications",
            },
          ]}
        />
      </Space> */}
      {!isMobile && isAdmin() && (
        <Space>
          <UserSummaryDrawer users={filteredUsers} />
          <DownloadExcel data={users} />
        </Space>
      )}
      {getUserAccess()?.accessHours > 0 && getUserAccess()?.accessHours < 9999 && (
        <Tooltip
          color="firebrick"
          title={`For security reasons, you will have access to the application for only ${
            getUserAccess()?.accessHours
          } hours after it's created. Please complete it within time as sensitive info like ssn_last4 will be removed after that as well.`}
        >
          <Space style={{ fontSize: "20px" }}>
            <PiClockDuotone color="firebrick" style={{ fontSize: "25px", transform: "translateY(3px)" }} />
            {getUserAccess()?.accessHours} hours access
          </Space>
        </Tooltip>
      )}
    </Space>
  );
  const selectCenter = (
    <Space>
      {!isMobile && <h3 level={5}>Your Health Center</h3>}
      <Select
        dropdownMatchSelectWidth
        showSearch
        mode="tag"
        style={{
          width: isMobile ? "200px" : "300px",
        }}
        allowClear
        onClear={() => setCenterName("-")}
        value={centerName ?? "-"}
        onChange={setCenterName}
        onSelect={handleSetAmbassadorField}
        options={centers?.names
          .filter((n) => {
            return String(n).includes(String(loc)?.toUpperCase());
          })
          .map((n) => ({
            key: n,
            value: n,
          }))}
      />
    </Space>
  );

  const users_table = (
    <Table
      pagination={{
        position: ["topRight", "bottomRight"],
        defaultPageSize: 20, // Number of items per page
        defaultCurrent: 1, // Starting at page 2 for items 50-100
      }}
      style={{ fontSize: "15px !important" }}
      rowClassName={getRowClassName}
      scroll={{ x: 300, y: 700 }}
      loading={!users || updating}
      size={isMobile ? "small" : "small"}
      rowKey={(elem) => {
        return JSON.stringify(elem);
      }}
      // onRow={handleRowClick}
      key="id"
      title={() => titleData}
      columns={[
        {
          title: "Type",
          render: (user) => {
            const renderList = [{ title: "Remote Submission", render: () => user.input_by_user }];
            return (
              <ul style={{ fontSize: "10px" }}>
                {renderList.map((r) => {
                  if (r.render()) return <li>{r.title}</li>;
                })}
              </ul>
            );
          },
        },
        {
          title: "Insurance/Benefit",
          dataIndex: "application_type", // Assuming 'application_type' holds the value you want to filter by
          key: "type",
          width: 150,
          fixed: isMobile ? "" : "left",
          render: (application_type) => {
            if (application_type?.includes("none")) return <b style={{ color: "grey" }}>None</b>;
            return <b>{application_type}</b>;
          },
          filters: [
            { text: "Medicaid", value: "Medicaid" },
            { text: "Medicare", value: "Medicare" },
            { text: "SNAP", value: "SNAP" },
          ],
          onFilter: (value, record) => record.application_type === value,
        },
        {
          width: 200,
          title: "Status",
          render: (user) => {
            return generateTags(user);
          },
        },
        {
          title:"Application Status",
          width:300,
          render:(user)=><ApplicationStatus user={user}/>
        },
        {
          title: "Name",
          width: 200,
          render: (user) => {
            try {
              if ((!user.name || user.name === "") && user.first_name) {
                user.name = `${user.first_name} ${user.last_name}`;
              }
              const name =
                user.name && user.name !== "" ? (
                  user.name
                ) : (
                  <p style={{ color: "grey" }}>
                    <i>Name not entered</i>
                  </p>
                );
              if (isFellow() || isAdmin() || isRemoteAmbassador()) return <Link to={`user?id=${user.id}`}>{name}</Link>;
              else return name;
            } catch (err) {
              return;
            }
          },
        },
        {
          title: "Gender",
          width: 80,
          render: (user) => {
            return user.gender;
          },
        },
        {
          title: "Phone",
          render: (user) => {
            return user.phone;
          },
        },
        {
          title: "Email",
          width: 230,
          render: (user) => {
            return <div style={{ fontSize: "12px" }}>{user.email}</div>;
          },
        },

        {
          title: "Date of Birth (dd-mm-yyyy)",
          width: 120,
          render: (user) => {
            return <div style={{ fontSize: "13px" }}>{formatTime(user.date_of_birth?.value ?? user.date_of_birth, "dd-mm-yyyy")}</div>;
          },
        },
        {
          title: "Address",
          width: 400,
          render: (user) => {
            return <div style={{ fontSize: "12px" }}>{user.address}</div>;
          },
        },
        {
          title: "Language",
          width: 200,
          render: (user) => {
            return <div style={{ fontSize: "12px" }}>{user.preferred_language}</div>;
          },
        },
        {
          title: "Living with others",
          render: (user) => {
            if (user.live_with_others === false) return "❌";
            else if (user.live_with_others === true) return "✔️";
          },
        },
        {
          title: "US Citizen",
          render: (user) => {
            if (user.us_citizen === false) return "❌";
            else if (user.us_citizen === true) return "✔️";
          },
        },
        {
          title: "SSN (last 4 digits)",
          render: (user) => {
            return user.ssn_last4;
          },
        },
        {
          title: "Created on",
          dataIndex: "createdOn",
          render: (ms) => {
            // Convert the milliseconds to a readable date string
            return new Date(ms).toDateString();
          },
          sorter: (a, b) => {
            const dateA = new Date(a.createdOn);
            const dateB = new Date(b.createdOn);

            // Check if both dates are valid
            if (isNaN(dateA) && isNaN(dateB)) return 0;
            if (isNaN(dateA)) return 1;
            if (isNaN(dateB)) return -1;

            // Compare the valid dates
            return dateA - dateB;
          },

          defaultSortOrder: "descend",
        },
        // {
        //   title: "Loc",
        //   dataIndex: "loc",
        //   render: (id) => {
        //     return <div style={{ fontSize: "15px" }}>{id?.value ?? id}</div>;
        //   },
        // },
        {
          title: "Health Center",
          dataIndex: "ambassadorName",
          render: (center, user) => {
            try {
              const value = center?.value ?? center;

              // Function to open modal
              const openEditModal = () => {
                Modal.info({
                  title: "Edit Ambassador Name",
                  content: (
                    <Select
                      style={{ width: "100%" }}
                      placeholder="Select new value"
                      onChange={(newValue) => handleUpdateUser(user.id, { ambassadorName: newValue })}
                      options={centers?.names?.map((opt) => ({
                        key: opt,
                        value: opt,
                        label: opt,
                      }))}
                    />
                  ),
                  onOk() {
                    console.log("Modal closed");
                  },
                });
              };

              return (
                <div style={{ fontSize: "15px", display: "flex", alignItems: "center" }}>
                  {/* Displaying the value */}
                  {value}

                  {/* Only show edit options if the user is an admin and centers array exists */}
                  {isAdmin() && Array.isArray(centers?.names) && (
                    <>
                      {/* Edit Icon */}
                      <EditOutlined style={{ marginLeft: "8px", cursor: "pointer" }} onClick={openEditModal} />
                    </>
                  )}
                </div>
              );
            } catch (error) {
              console.error("Error rendering center value:", error);
              return <div style={{ fontSize: "15px" }}>Err {JSON.stringify(error)}</div>;
            }
          },
        },
        {
          title: (
            <>
              View User Filled Applications
              <br />
              <i>
                <small>these applications are filled by users</small>
              </i>
            </>
          ),
          width: 200,
          render: (user) => {
            if (user.input_by_user) {
              if (user.snap_full_uploaded)
                return (
                  <Space>
                    <Button
                      type="link"
                      size="small"
                      onClick={() => {
                        Modal.info({
                          maskClosable: true,
                          closable: true,
                          width: "90%",
                          title: "User WIC texas Form",
                          content: (
                            <>
                              <Typography.Title>User SNAP Application PDF</Typography.Title>
                              <SNAPPdf user={users.find((userComplete) => user.id === userComplete.id)} />
                              {/* <FormComponent displayOnly displaySteps={false} initialValues={user} formData={wicTexasConfig} /> */}
                            </>
                          ),
                        });
                      }}
                    >
                      View their SNAP application pdf
                    </Button>
                  </Space>
                );
              if (user.texas_wic_uploaded)
                return (
                  <Space direction="vertical">
                    <Button
                      type="link"
                      size="small"
                      onClick={() => {
                        Modal.info({
                          maskClosable: true,
                          closable: true,
                          width: "90%",
                          title: "User WIC texas Form",
                          content: (
                            <>
                              <Typography.Title>User WIC Texas Form Input</Typography.Title>
                              <FormComponent displayOnly displaySteps={false} initialValues={user} formData={wicTexasConfig} />
                              {!user.texas_wic_application_processed ? (
                                <Button
                                  type="primary"
                                  onClick={() => {
                                    catcher(async () => {
                                      await handleUpdateUser(user.id, { texas_wic_application_processed: true });
                                    }, {});
                                  }}
                                >
                                  Mark as processed?
                                </Button>
                              ) : (
                                <Alert message="Application has been marked as processed!"></Alert>
                              )}
                            </>
                          ),
                        });
                      }}
                    >
                      Texas WIC Submission
                    </Button>
                    {/* <Button>Mark as done?</Button> */}
                  </Space>
                );
            }
          },
        },
        // {
        //   title: "ID",
        //   dataIndex: "id",
        //   render: (id) => {
        //     return <div style={{ fontSize: "10px" }}>{id?.value ?? id}</div>;
        //   },
        // },

        {
          title: <p style={{ fontSize: "12px" }}>Lifeline Enrollment Completed</p>,
          // fixed: isMobile ? "" : "right",
          width: 120,
          render: (user) => {
            if (!user.lifeline_uploaded) return null;
            return (
              <div style={{ width: "100%" }}>
                <Select
                  size="small"
                  style={{ width: "100%" }}
                  value={user.lifeline_enrollment_completed ? "Yes" : "No"}
                  onChange={(value) =>
                    handleUpdateUser(user?.id ?? user.id, {
                      lifeline_enrollment_completed: {
                        value: value === "Yes",
                      },
                    })
                  }
                >
                  <Option value="Yes">Yes</Option>
                  <Option value="No">No</Option>
                </Select>
              </div>
            );
          },
        },
        {
          title: <p style={{ fontSize: "12px" }}>SMS Sent to Patient</p>,
          // fixed: isMobile ? "" : "right",
          width: 100,
          render: (user) => {
            if (!user.lifeline_uploaded) return null;
            return (
              <div style={{ width: "100%" }}>
                <Select
                  size="small"
                  style={{ width: "100%" }}
                  value={user.sms_sent_to_patient ? "Yes" : "No"}
                  onChange={(value) =>
                    handleUpdateUser(user?.id ?? user.id, {
                      sms_sent_to_patient: { value: value === "Yes" },
                    })
                  }
                >
                  <Option value="Yes">Yes</Option>
                  <Option value="No">No</Option>
                </Select>
              </div>
            );
          },
        },

        {
          title: "Notes",
          fixed: isMobile ? "" : "right",
          width: 150,
          render: (user) => {
            return (
              <NotesComponent
                isMobile={isMobile}
                initialNotes={user.notes ?? ""}
                onSave={async (notes) => {
                  await handleUpdateUser(
                    user?.id ?? user.id,
                    {
                      notes,
                    },
                    true
                  );
                }}
              />
            );
          },
        },
        {
          title: "Options",
          // fixed: isMobile ? "" : "right",
          width: 100,
          render: (user) => {
            return (
              <div>
                {/* <Button onClick={() => generateSnapPdf(user.id)}>
                SNAP <FaDownload />
              </Button> */}
                <Button
                  shape="round"
                  danger
                  onClick={async (e) => {
                    await handleDelete(user);
                    e.stopPropagation();
                    e.preventDefault();
                  }}
                >
                  <AiOutlineDelete />{" "}
                </Button>
              </div>
            );
          },
        },
      ].map((item) => ({ ...item, width: item.width ?? 150 }))}
      dataSource={
        users
          ? users.map((user) => {
              return flattenJson(user);
            })
          : []
      }
    />
  );

  return (
    <div className="home">
      <div
        style={{
          display: "flex",
          gap: 10,
          padding: 10,
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Space>
          <img src="https://i0.wp.com/link-health.org/wp-content/uploads/2022/10/Link-Health-transparent.png?resize=800%2C800&ssl=1" alt="LINK HEALTH LOGO" style={flagStyle} />
          <div style={{ textTransform: "capitalize", textDecoration: "underline", display: isMobile && "none" }}>Hi {auth.currentUser.email.split("@")[0]}!</div>
        </Space>
        {selectCenter}
        <Space>
          {isAdmin() && !isMobile && (
            <Link to="/config">
              <Button className="flexbtn">
                <IoSettingsSharp />
                Admin Config
              </Button>
            </Link>
          )}
          <Button type="primary" style={{ background: "firebrick" }} onClick={logout} className="flexbtn">
            {!isMobile && "LOGOUT"} <AiOutlineLogout />
          </Button>
        </Space>
      </div>
      <HeadingContainer title={`${getTimeBasedGreeting()}`} />
      <div className="patients">
        <div className="new-patient" style={{ justifyContent: "space-between", zoom: isAmbassador() ? 1.3 : 1 }}>
          <Space style={{ padding: isMobile ? "3px 4px" : "20px 0px 0px 0px" }} className={isMobile ? "full-width-space" : ""}>
            {isMobile ? "" : <h1 level={3}>Dashboard</h1>}
            <Button shape="default" type="primary" size="large" onClick={handleNewUser}>
              + New user application
            </Button>
          </Space>
        </div>

        {users_table}
      </div>
      <Modal
        closeIcon={null}
        open={modals.selectCenterModal}
        footer={[
          <Button key={"submit"} type="primary" onClick={() => setModals({})}>
            {" "}
            Save{" "}
          </Button>,
        ]}
      >
        <Typography.Title level={5}>Please select your health center first.</Typography.Title>
        {selectCenter}
      </Modal>
    </div>
  );
}
